import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import DropdownMenu from './DropdownMenu';
import Logo from './LOGOjuandavid2.svg';
import Footer from './Footer';
import HomePage from './HomePage';
import ProceduresPage from './pages/ProceduresPage';
import SurgeriesPage from './pages/SurgeriesPage';

function App() {
  // ✅ Inline redirect component
  const AboutRedirect = () => {
    useEffect(() => {
      window.location.replace("https://app.drjuansalazar.com/about-f84fdd9c-1fa5-4cc3-8786-bc33e777374f");
    }, []);

    return null; // No UI is rendered
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <img src={Logo} className="App-logo" alt="logo" />
          </Link>
          <DropdownMenu />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutRedirect />} /> {/* ✅ Immediate redirect */}
            <Route path="/procedures" element={<ProceduresPage />} />
            <Route path="/surgeries" element={<SurgeriesPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
