import React from 'react';
import './SurgeryModal.css';

const SurgeryModal = ({ surgery, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-body">
          <div className="modal-text">
            <h2>{surgery.name}</h2>
            <p>{surgery.content.split('\n').map((line, index) => (
              <span key={index}>{line}<br /></span>
            ))}</p>
            <a
              href="https://app.drjuansalazar.com/offers/oLqvv6Ge/checkout"
              target="_blank"
              rel="noopener noreferrer"
              className="book-button"
            >
              Book Appointment
            </a>
          </div>
          <div className="modal-image-container">
            <img src={surgery.image} alt={surgery.name} className="modal-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurgeryModal;
