// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import SurgeonProfile from './SurgeonProfile';
import InteractiveImageComponent from './InteractiveImageComponent';
import ContactImage from './ContactImage.png';
import ButtonImage from './ButtonImage.svg';

const images = [
    'homep.png',
    'rio.png',
    'col.png',
];

function HomePage() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    const handleWhatsAppClick = () => {
        window.open('https://wa.link/ywaevp', '_blank');
    };

    return (
        <div>
            <section className="App-slideshow">
                <div className="image-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index}`}
                            className={`fade-image ${index === currentImageIndex ? 'visible' : ''}`}
                        />
                    ))}
                    <div className="slideshow-text">
                        <p2 className="welcome-text">WELCOME TO</p2>
                        <h1 className="headline-text">
                            YOUR <br />NEW YOU
                        </h1>

                        <p className="subheadline-text">Plastic & Reconstructive Surgery</p>
                        <a
                            className="book-appointment-btn"
                            href="https://app.drjuansalazar.com/offers/oLqvv6Ge/checkout"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Book Appointment
                        </a>
                    </div>
                </div>
            </section>

            <SurgeonProfile />

            <InteractiveImageComponent />

            <div className="contact-image-container">
                <img src={ContactImage} alt="Contact" className="contact-image" />
                <button className="whatsapp-btn" onClick={handleWhatsAppClick}>
                    <img src={ButtonImage} alt="WhatsApp Icon" className="button-image" /> WhatsApp
                </button>
            </div>
        </div>
    );
}

export default HomePage;
